*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Poppins, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

html {
  scroll-behavior: smooth;
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(28 30 36 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-family: Poppins, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.625rem;
}

@media (min-width: 834px) {
  body {
    font-size: 1.125rem;
    line-height: 2rem;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

h1, h2, h3 {
  --tw-text-opacity: 1;
  color: rgb(220 239 255 / var(--tw-text-opacity));
  font-weight: 600;
}

h1 {
  font-size: 1.5rem;
  line-height: 2.25rem;
}

@media (min-width: 834px) {
  h1 {
    font-size: 2rem;
    line-height: 3rem;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 2.625rem;
    line-height: 4rem;
  }
}

h2 {
  font-size: 1.375rem;
  line-height: 2rem;
}

@media (min-width: 834px) {
  h2 {
    font-size: 1.75rem;
    line-height: 2.625rem;
  }
}

@media (min-width: 1024px) {
  h2 {
    font-size: 2.375rem;
    line-height: 3.625rem;
  }
}

h2 {
  text-align: center;
}

@media (min-width: 1024px) {
  h2 {
    text-align: left;
  }
}

h3 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (min-width: 834px) {
  h3 {
    font-size: 1.375rem;
    line-height: 2rem;
  }
}

@media (min-width: 1024px) {
  h3 {
    font-size: 1.75rem;
    line-height: 2.625rem;
  }
}

a {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 834px) {
  .container {
    max-width: 834px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.container {
  max-width: 1150px !important;
}

.subtitle {
  --tw-text-opacity: 1;
  color: rgb(139 157 186 / var(--tw-text-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 834px) {
  .subtitle {
    font-size: 1.125rem;
    line-height: 2rem;
  }
}

@media (min-width: 1024px) {
  .subtitle {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.visible {
  visibility: visible;
}

.relative {
  position: relative;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mr-0 {
  margin-right: 0;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.max-w-full {
  max-width: 100%;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-xs {
  font-size: .8125rem;
  line-height: 1.25rem;
}

.font-normal {
  font-weight: 300;
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(0 136 204 / var(--tw-text-opacity));
}

.text-gray {
  --tw-text-opacity: 1;
  color: rgb(139 157 186 / var(--tw-text-opacity));
}

.text-light {
  --tw-text-opacity: 1;
  color: rgb(220 239 255 / var(--tw-text-opacity));
}

.text-opacity-70 {
  --tw-text-opacity: .7;
}

.logo {
  width: 9rem;
  position: absolute;
  top: .5rem;
  left: 1.25rem;
}

@media (min-width: 1536px) {
  .logo {
    left: -4rem;
  }
}

@media (min-width: 834px) {
  .logo {
    width: 14.5rem;
    padding-left: 1.5rem;
    top: .875rem;
  }
}

@media (min-width: 1280px) {
  .logo {
    left: -4rem;
  }
}

.subscribe {
  font-size: .875rem;
  line-height: 1.25rem;
  position: absolute;
  top: .625rem;
  right: 1.25rem;
}

@media (min-width: 1536px) {
  .subscribe {
    right: -4rem;
  }
}

@media (min-width: 834px) {
  .subscribe {
    padding-right: 1.5rem;
    top: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .subscribe {
    right: -4rem;
  }
}

.subscribe {
  --tw-border-opacity: 1;
  border-color: rgb(0 136 204 / var(--tw-border-opacity));
  white-space: nowrap;
  border-width: 1px;
  border-radius: 9999px;
  align-items: center;
  padding: .5rem 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.subscribe:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.subscribe svg {
  margin-right: .5rem;
}

.proposition {
  background-image: url("proposition_bg--sm.cc809b14.svg");
  background-position: 50% 90%;
  background-repeat: no-repeat;
  background-size: 250%;
}

@media (min-width: 500px) {
  .proposition {
    background-position: 50% 70%;
  }
}

@media (min-width: 834px) {
  .proposition {
    background-image: url("proposition_bg--md.b0b82e87.svg"), url("proposition_ring--md.3707ea01.svg");
    background-position: calc(50% + 7rem) 0, 0 0;
    background-size: 90%, 60%;
  }
}

@media (min-width: 1024px) {
  .proposition {
    background-image: url("proposition_bg.df0e960b.svg"), url("proposition_ring.57df8a65.svg");
    background-position: calc(50% + 10rem) 55%, 0 0;
    background-size: 75%, 50%;
  }
}

@media (min-width: 1300px) {
  .proposition {
    background-position: calc(50% + 12rem) 55%, 0 0;
    background-size: 70%, 50%;
  }
}

@media (min-width: 1800px) {
  .proposition {
    background-size: 60%, 50%;
  }
}

.proposition .container {
  height: 100%;
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 4.75rem;
  position: relative;
}

@media (min-width: 834px) {
  .proposition .container {
    padding-top: 13.75rem;
    padding-bottom: 10.5rem;
  }
}

@media (min-width: 1024px) {
  .proposition .container {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
}

.proposition__text {
  text-align: center;
}

@media (min-width: 834px) {
  .proposition__text {
    width: 26rem;
    text-align: left;
    padding-left: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .proposition__text {
    width: 42rem;
    padding-left: 2.9rem;
  }
}

.proposition__text p {
  margin-top: 1.25rem;
}

@media (min-width: 834px) {
  .proposition__text p {
    width: 26rem;
    margin-top: 1rem;
  }
}

@media (min-width: 1024px) {
  .proposition__text p {
    width: 30rem;
  }
}

.proposition-screens {
  height: 350px;
  max-width: 28rem;
  margin-top: 3.25rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

@media (min-width: 834px) {
  .proposition-screens {
    max-width: none;
    margin-top: 2.75rem;
    position: absolute;
    left: 50%;
    overflow: visible;
  }
}

.proposition-screens {
  background: #8b9dba33;
  border-radius: 30px;
}

@media (min-width: 834px) {
  .proposition-screens {
    background: none;
    top: 125px;
    transform: translateX(82%);
  }
}

@media (min-width: 1024px) {
  .proposition-screens {
    background: none;
    top: 91px;
    transform: translateX(95%);
  }
}

.proposition-screens__item {
  position: absolute;
}

.proposition-screens__item--dark {
  width: 198px;
  height: 402px;
  top: 2.5rem;
  left: .875rem;
}

@media (min-width: 834px) {
  .proposition-screens__item--dark {
    width: 157px;
    height: 329px;
    top: 5.25rem;
    left: -6.5rem;
  }
}

@media (min-width: 1024px) {
  .proposition-screens__item--dark {
    width: 210px;
    height: 425px;
    top: 5.5rem;
    left: -7.5rem;
  }
}

.proposition-screens__item--light {
  top: 1rem;
  right: .875rem;
}

@media (min-width: 834px) {
  .proposition-screens__item--light {
    position: relative;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1024px) {
  .proposition-screens__item--light {
    top: 1.5rem;
    right: -1.25rem;
  }
}

.proposition-screens__item--light {
  width: 218px;
  height: 457px;
  display: inline-block;
}

@media (min-width: 834px) {
  .proposition-screens__item--light {
    width: 204px;
    height: 424px;
  }
}

@media (min-width: 1024px) {
  .proposition-screens__item--light {
    width: 250px;
    height: 521px;
  }
}

.features {
  --tw-bg-opacity: 1;
  background-color: rgb(35 44 58 / var(--tw-bg-opacity));
  padding-top: 4rem;
  padding-bottom: 2rem;
}

@media (min-width: 834px) {
  .features {
    padding-top: 4rem;
    padding-bottom: 0;
    padding-left: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .features {
    padding-top: 7rem;
    padding-bottom: 3.1rem;
    padding-left: 0;
  }
}

.features .container {
  width: 100%;
}

@media (min-width: 1024px) {
  .features .container {
    padding-left: 4.2rem;
    display: flex;
  }
}

@media (min-width: 834px) {
  .features__wrapper {
    flex-wrap: wrap;
    margin-top: 2.25rem;
    display: flex;
  }
}

@media (min-width: 1024px) {
  .features__wrapper {
    margin-top: 0;
  }
}

@media (min-width: 1280px) {
  .features__wrapper {
    position: relative;
    right: -4.8rem;
  }
}

@media (min-width: 834px) {
  .features__wrapper h3 {
    letter-spacing: .025em;
  }
}

@media (min-width: 1024px) {
  .features__wrapper h3 {
    letter-spacing: 0;
  }
}

@media (min-width: 834px) {
  .features__wrapper h3 {
    margin: 1.6rem 0 .7rem;
  }
}

@media (min-width: 1024px) {
  .features__wrapper h3 {
    margin: 1.6rem 0 1rem;
  }
}

.features__wrapper span {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(139 157 186 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(139 157 186 / var(--tw-text-opacity));
  border-radius: 9999px;
  margin-top: 1rem;
  margin-bottom: 2.25rem;
  padding: .125rem .875rem;
  font-weight: 300;
  line-height: 1.25rem;
  display: inline-block;
}

@media (min-width: 834px) {
  .features__wrapper span {
    margin-top: 2.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .features__wrapper span {
    margin-top: 1.5rem;
  }
}

.feature {
  text-align: center;
}

@media (min-width: 834px) {
  .feature {
    text-align: left;
    max-width: calc(50% - 1.5rem);
    flex-basis: calc(50% - 1.5rem);
    margin-bottom: 3.75rem;
  }

  .feature:nth-child(2n+1) {
    margin-right: 1.5rem;
  }
}

.feature__box {
  height: 9.25rem;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

@media (min-width: 834px) {
  .feature__box {
    height: 11rem;
  }
}

@media (min-width: 1024px) {
  .feature__box {
    width: 20rem;
  }
}

@media (min-width: 1280px) {
  .feature__box {
    width: 25rem;
  }
}

.feature__box {
  background: linear-gradient(#2c4e97 0%, #6499cc 100%);
  border-radius: 1rem;
  overflow: hidden;
}

.feature__image {
  max-width: 50%;
}

.feature__image--full {
  max-width: 100%;
}

.feature h3 {
  margin-top: 1rem;
  margin-bottom: .625rem;
}

.feature p {
  --tw-text-opacity: 1;
  color: rgb(139 157 186 / var(--tw-text-opacity));
}

.get-chrome, .get-desktop {
  --tw-bg-opacity: 1;
  background-color: rgb(0 136 204 / var(--tw-bg-opacity));
  border-radius: 9999px;
  display: inline-block;
}

.get-chrome:active, .get-desktop:active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 99 148 / var(--tw-bg-opacity));
}

.get-chrome, .get-desktop {
  margin-bottom: 1rem;
  padding: .625rem 3.75rem .625rem 1.5rem;
}

@media (min-width: 834px) {
  .get-chrome, .get-desktop {
    padding: .75rem 70px .75rem 2.25rem;
  }
}

@media (min-width: 1024px) {
  .get-chrome, .get-desktop {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

.get-chrome, .get-desktop {
  cursor: pointer;
  text-align: left;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
}

.get-chrome.no-icon, .get-desktop.no-icon {
  padding: .625rem 2rem;
}

.get-chrome.dimmed, .get-desktop.dimmed {
  background-color: #0088cc54 !important;
}

.get-chrome__text, .get-desktop__text {
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.625rem;
  display: block;
  position: relative;
}

@media (min-width: 834px) {
  .get-chrome__text, .get-desktop__text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .get-chrome__text, .get-desktop__text {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

.get-chrome:hover:before, .get-desktop:hover:before {
  opacity: 1;
}

.get-chrome:active:before, .get-desktop:active:before {
  opacity: 0;
}

.get-chrome:before, .get-desktop:before {
  --tw-bg-opacity: 1;
  background-color: rgb(16 163 237 / var(--tw-bg-opacity));
  content: "";
  width: 100%;
  height: 100%;
  filter: drop-shadow(0 4px 20px #10a3ed73);
  opacity: 0;
  pointer-events: none;
  border-radius: 9999px;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.get-chrome:not(.no-icon):after, .get-desktop:not(.no-icon):after {
  height: 2.5rem;
  width: 2.5rem;
  background-size: contain;
  display: block;
  position: absolute;
  top: 50%;
  right: .25rem;
}

@media (min-width: 834px) {
  .get-chrome:not(.no-icon):after, .get-desktop:not(.no-icon):after {
    height: 2.75rem;
    width: 2.75rem;
    right: .125rem;
  }
}

.get-chrome:not(.no-icon):after, .get-desktop:not(.no-icon):after {
  content: "";
  transform: translateY(-50%);
}

.get-chrome:not(.no-icon):after {
  background-image: url("chrome.b5ea174c.svg");
}

.get-desktop:not(.no-icon):after {
  background: #fff3 url("laptop.78dab5f5.png") center / 65% no-repeat;
  border-radius: 9999px;
}

.learn-more {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(139 157 186 / var(--tw-text-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
  display: none;
}

@media (min-width: 1024px) {
  .learn-more {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: absolute;
    bottom: 9rem;
    left: 50%;
  }
}

.learn-more:after {
  content: "";
  width: 30px;
  height: 52px;
  background-image: url("arrow.28543a21.svg");
  background-size: cover;
  display: block;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.teasers {
  background-repeat: no-repeat;
  overflow: hidden;
}

@media (min-width: 834px) {
  .teasers {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .teasers {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.teasers {
  background-image: url("teasers_bg.241184d6.svg");
  background-position: 50% -25vw;
}

@media (min-width: 600px) {
  .teasers {
    background-position: 50% -39vw;
  }
}

@media (max-width: 833.99px) {
  .teasers {
    background-size: 100%;
  }
}

@media (min-width: 834px) {
  .teasers {
    background-image: url("teasers_bg--md.338ef64c.svg");
    background-position: 50% -13rem;
  }
}

@media (min-width: 1024px) {
  .teasers {
    background-image: url("teasers_bg--md.338ef64c.svg");
    background-position: calc(50% - 10rem) -13rem;
  }
}

.teasers .container {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 0;
}

@media (min-width: 834px) {
  .teasers .container {
    padding-top: 6rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 1024px) {
  .teasers .container {
    padding-bottom: 6rem;
  }
}

.teasers .container {
  background-image: url("teasers_ring.feca1187.svg");
  background-position: 50% 95%;
  background-repeat: no-repeat;
  background-size: 100%;
}

@media (min-width: 834px) {
  .teasers .container {
    background-image: none;
    padding-top: 6rem;
  }
}

@media (min-width: 1024px) {
  .teasers .container {
    padding-top: 6.35rem;
  }
}

.teasers-screen {
  display: block;
  position: relative;
}

.teasers-screen--desktop {
  margin: 1.25rem auto 3rem;
}

@media (min-width: 834px) {
  .teasers-screen--desktop {
    margin-top: 1.5rem;
    margin-bottom: 7.5rem;
  }
}

@media (min-width: 1024px) {
  .teasers-screen--desktop {
    margin-top: 7rem;
  }
}

@media (min-width: 834px) {
  .teasers-screen--desktop {
    width: 700px;
    height: 438px;
  }
}

@media (min-width: 1024px) {
  .teasers-screen--desktop {
    width: 700px;
    height: 470px;
    position: relative;
    left: .75rem;
  }
}

.teasers-screen--mobile {
  filter: drop-shadow(-10px 4px 20px #00121b40);
  position: absolute;
  top: 3.75rem;
}

.teasers-screen--mobile picture {
  z-index: 2;
  position: relative;
}

.teasers-screen--mobile {
  width: 30%;
  right: 2.5rem;
}

@media (min-width: 834px) {
  .teasers-screen--mobile {
    width: 167px;
    height: 339px;
    top: 7.25rem;
    right: 5.5rem;
  }
}

@media (min-width: 1024px) {
  .teasers-screen--mobile {
    width: 188px;
    height: 382px;
    right: 14.5rem;
  }
}

.teasers-popup {
  z-index: 10;
  width: 100%;
  margin-top: .75rem;
  margin-bottom: .75rem;
  padding: 2rem;
}

@media (min-width: 834px) {
  .teasers-popup {
    margin-top: 0;
    margin-bottom: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .teasers-popup {
    width: 24.5rem;
    margin: 0;
    padding-top: 2.75rem;
    position: absolute;
  }
}

.teasers-popup {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #253245a6;
  background-image: url("teasers_stroke.65f6c9af.svg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 25px;
}

.teasers-popup h3 {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(220 239 255 / var(--tw-text-opacity));
  margin-bottom: .625rem;
}

@media (min-width: 834px) {
  .teasers-popup h3 {
    margin-bottom: 1rem;
  }
}

@media (min-width: 1024px) {
  .teasers-popup h3 {
    text-align: left;
    margin-bottom: .625rem;
  }
}

.teasers-popup p {
  text-align: center;
}

@media (min-width: 1024px) {
  .teasers-popup p {
    text-align: left;
  }
}

.all-devices h3 {
  text-align: center;
  margin-top: 5rem;
}

@media (min-width: 834px) {
  .all-devices h3 {
    margin-top: 6.5rem;
    margin-bottom: 5rem;
  }
}

@media (min-width: 1024px) {
  .all-devices h3 {
    margin-top: 18rem;
    margin-bottom: 6rem;
  }
}

.all-devices__icons {
  width: 100%;
  flex-direction: column;
  margin-top: 5rem;
  display: flex;
}

@media (min-width: 834px) {
  .all-devices__icons {
    flex-direction: row;
    justify-content: center;
  }
}

.all-devices__icon {
  height: 5.5rem;
  width: 7.5rem;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (min-width: 834px) {
  .all-devices__icon {
    margin-bottom: 0;
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
}

.all-devices__icon:nth-child(1) {
  background-image: url("laptop.9497708c.svg");
}

.all-devices__icon:nth-child(2) {
  background-image: url("tablet.1e866121.svg");
}

.all-devices__icon:nth-child(3) {
  background-image: url("phone.e9d9e7f2.svg");
}

@media (min-width: 834px) {
  .all-devices__icon:nth-child(3) {
    background-position: 0 calc(100% - 2.5px);
  }
}

.disclaimer {
  padding-bottom: 4rem;
}

@media (min-width: 834px) {
  .disclaimer {
    padding-bottom: 5.5rem;
  }
}

.disclaimer h3 {
  margin-top: 1rem;
  margin-bottom: 1.8rem;
}

@media (min-width: 834px) {
  .disclaimer h3 {
    margin-top: 6.5rem;
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .disclaimer h3 {
    margin-top: 0;
    margin-bottom: 3rem;
  }
}

.disclaimer p:not(:last-child) {
  margin-bottom: 1rem;
}

.footer {
  --tw-bg-opacity: 1;
  background-color: rgb(35 44 58 / var(--tw-bg-opacity));
  text-align: center;
  background-image: url("footer_bg.47bff34d.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 200%;
}

@media (min-width: 1024px) {
  .footer {
    background-size: 100%;
  }
}

.footer .container {
  width: 100%;
  padding-top: 1.75rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 834px) {
  .footer .container {
    padding-top: 2.5rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 1024px) {
  .footer .container {
    padding-top: 3.5rem;
    padding-bottom: 1.5rem;
  }
}

.footer .container {
  overflow: hidden;
}

.footer__menu {
  text-align: center;
  margin-top: 2rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-top: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (min-width: 834px) {
  .footer__menu {
    margin-top: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .footer__menu {
    margin-top: 4rem;
  }
}

.footer__menu {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-top-color: rgb(220 239 255 / var(--tw-border-opacity));
  --tw-border-opacity: .3;
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer__menu > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)) );
}

@media (min-width: 1024px) {
  .footer__menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)) );
  }
}

.footer__menu a {
  --tw-text-opacity: 1;
  color: rgb(220 239 255 / var(--tw-text-opacity));
  --tw-text-opacity: .7;
  font-size: .8125rem;
  font-weight: 300;
  line-height: 1.25rem;
}

.footer__menu a:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

@media (min-width: 834px) {
  .footer__menu a {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-opacity-100:hover {
  --tw-text-opacity: 1;
}

@media (min-width: 834px) {
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mr-7 {
    margin-right: 1.75rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:w-98 {
    width: 26rem;
  }

  .md\:max-w-\[800px\] {
    max-width: 800px;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.625rem;
  }
}

@media (min-width: 1024px) {
  .lg\:-bottom-9 {
    bottom: -2.25rem;
  }

  .lg\:left-11 {
    left: 2.75rem;
  }

  .lg\:-top-28 {
    top: -7rem;
  }

  .lg\:right-0 {
    right: 0;
  }

  .lg\:-bottom-46 {
    bottom: -11.5rem;
  }

  .lg\:mt-15 {
    margin-top: 3.75rem;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:mt-40 {
    margin-top: 10rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:ml-12 {
    margin-left: 3rem;
  }

  .lg\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:mt-11 {
    margin-top: 2.75rem;
  }

  .lg\:w-100 {
    width: 30rem;
  }

  .lg\:text-center {
    text-align: center;
  }
}

@media (min-width: 1280px) {
  .xl\:-right-14 {
    right: -3.5rem;
  }
}

/*# sourceMappingURL=index.c9a3bdf0.css.map */
