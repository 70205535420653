@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    @apply bg-background-dark font-sans text-white font-medium text-sm md:text-base;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  h1,
  h2,
  h3 {
    @apply font-bold text-light;
  }

  h1 {
    @apply text-2xl md:text-4xl lg:text-6xl;
  }

  h2 {
    @apply text-xl md:text-3xl lg:text-5xl;
    @apply text-center lg:text-left;
  }

  h3 {
    @apply text-base leading-7 md:text-xl lg:text-3xl;
  }

  a {
    @apply transition-colors duration-200;
  }
}

@layer components {
  .container {
    max-width: 1150px !important;
  }

  .subtitle {
    @apply text-gray text-md md:text-base lg:text-lg;
  }
}

.logo {
  @apply absolute left-5 top-2 2xl:-left-16 w-36;
  @apply md:w-58 md:top-3.5 md:pl-6;
  @apply xl:-left-16;
}

.subscribe {
  @apply absolute right-5 top-2.5 2xl:-right-16 text-sm leading-5;
  @apply md:top-6 md:pr-6 xl:-right-16;
  @apply flex items-center;
  @apply border-blue;
  @apply transition-colors duration-200;

  display: flex;
  white-space: nowrap;
  padding: 0.5rem 1.25rem;
  border-width: 1px;
  border-radius: 9999px;

  &:hover {
    @apply text-white border-white;
  }
}

.subscribe svg {
  margin-right: 0.5rem;
}

.proposition {
  background-image: url(./images/proposition_bg--sm.svg);
  background-position: 50% 90%;
  background-repeat: no-repeat;
  background-size: 250%;

  @media (min-width: 500px) {
    background-position: 50% 70%;
  }

  @media (min-width: 834px) {
    background-image: url(./images/proposition_bg--md.svg),
      url(./images/proposition_ring--md.svg);
    background-size: 90%, 60%;
    background-position: calc(50% + 7rem) 0, left top;
  }

  @media (min-width: 1024px) {
    background-image: url(./images/proposition_bg.svg),
      url(./images/proposition_ring.svg);
    background-size: 75%, 50%;
    background-position: calc(50% + 10rem) 55%, left top;
  }

  @media (min-width: 1300px) {
    background-size: 70%, 50%;
    background-position: calc(50% + 12rem) 55%, left top;
  }

  @media (min-width: 1800px) {
    background-size: 60%, 50%;
  }

  .container {
    @apply h-full w-full relative pt-24 pb-19;
    @apply md:pt-54 md:pb-42;
    @apply lg:py-60;
  }

  &__text {
    @apply text-center;
    @apply md:w-98 md:text-left md:pl-6;
    @apply lg:w-110;

    @media (min-width: 1024px) {
      padding-left: 2.9rem;
    }

    p {
      @apply mt-5 md:mt-4 md:w-98 lg:w-100;
    }
  }
}

.proposition-screens {
  @apply h-90 mt-13 mx-auto overflow-hidden relative max-w-md;
  @apply md:mt-11 md:overflow-visible md:absolute md:left-1/2 md:max-w-none;

  background: rgba(139, 157, 186, 0.2);
  border-radius: 30px;

  @media (min-width: 834px) {
    background: none;
    top: 125px;
    transform: translateX(82%);
  }

  @media (min-width: 1024px) {
    background: none;
    top: 91px;
    transform: translateX(95%);
  }

  &__item {
    @apply absolute;

    &--dark {
      @apply left-3.5 top-10;

      width: 198px;
      height: 402px;

      @media (min-width: 834px) {
        top: 5.25rem;
        left: -6.5rem;
        width: 157px;
        height: 329px;
      }

      @media (min-width: 1024px) {
        top: 5.5rem;
        left: -7.5rem;
        width: 210px;
        height: 425px;
      }
    }

    &--light {
      @apply right-3.5 top-4;
      @apply md:relative md:right-0 md:top-0;
      @apply lg:-right-5 lg:top-6;

      width: 218px;
      height: 457px;
      display: inline-block;

      @media (min-width: 834px) {
        width: 204px;
        height: 424px;
      }

      @media (min-width: 1024px) {
        width: 250px;
        height: 521px;
      }
    }
  }
}

.features {
  @apply bg-background-light pt-16 pb-8;
  @apply md:pt-16 md:pb-0 md:pl-6;
  @apply lg:pt-28 lg:pl-0;

  @media (min-width: 1024px) {
    padding-bottom: 3.1rem;
  }

  .container {
    @apply w-full;
    @apply lg:flex lg:pl-3;

    @media (min-width: 1024px) {
      padding-left: 4.2rem;
    }
  }

  &__wrapper {
    @apply md:flex md:flex-wrap md:mt-9;
    @apply lg:mt-0;

    @media (min-width: 1280px) {
      position: relative;
      right: -4.8rem;
    }

    h3 {
      @apply md:tracking-wide;
      @apply lg:tracking-normal;

      @media (min-width: 834px) {
        margin: 1.6rem 0 0.7rem;
      }

      @media (min-width: 1024px) {
        margin: 1.6rem 0 1rem;
      }
    }

    span {
      @apply mt-4 mb-9 inline-block px-3.5 py-0.5 text-gray font-normal rounded-full border border-gray;
      @apply md:mb-0 md:text-md leading-5 md:leading-6;

      @media (min-width: 834px) {
        margin-top: 2.75rem;
      }

      @media (min-width: 1024px) {
        margin-top: 1.5rem;
      }
    }
  }
}

.feature {
  @apply text-center;
  @apply md:text-left md:mb-15;

  @media (min-width: 834px) {
    flex-basis: calc(50% - 1.5rem);
    max-width: calc(50% - 1.5rem);

    &:nth-child(odd) {
      margin-right: 1.5rem;
    }
  }

  &__box {
    @apply h-38 flex items-end justify-center;
    @apply md:h-44;
    @apply lg:w-80;
    @apply xl:w-97;

    overflow: hidden;
    background: linear-gradient(180deg, #2c4e97 0%, #6499cc 100%);
    border-radius: 1rem;
  }

  &__image {
    max-width: 50%;

    &--full {
      max-width: 100%;
    }
  }

  h3 {
    @apply mt-4 mb-2.5;
  }

  p {
    @apply text-gray;
  }
}

.get-chrome,
.get-desktop {
  @apply bg-blue active:bg-blueDark inline-block rounded-full;
  @apply mb-4;
  @apply py-2.5 pl-6 pr-15 md:py-3 md:pl-9 md:pr-[70px] lg:py-2;
  @apply cursor-pointer relative text-left;
  @apply transition-colors duration-200;

  &.no-icon {
    padding: .625rem 2rem;
  }

  &.dimmed {
    background-color: rgb(0 136 204 / 33%) !important;
  }

  &__text {
     @apply relative block text-sm md:text-md lg:text-base font-bold lg:font-medium;
  }

  &:hover:before {
    opacity: 1;
  }

  &:active {
    &:before {
      opacity: 0;
    }
  }

  &:before {
    @apply rounded-full block transition-opacity duration-200 bg-blueLight;

    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    filter: drop-shadow(0px 4px 20px rgba(16, 163, 237, 0.45));
    opacity: 0;
    pointer-events: none;
  }

  &:not(.no-icon):after {
    @apply block absolute top-1/2 right-1 bg-contain w-10 h-10 md:w-11 md:h-11 md:right-0.5;

    content: '';
    transform: translateY(-50%);
  }
}

.get-chrome:not(.no-icon) {
  &:after {
    background-image: url(./images/chrome.svg);
  }
}

.get-desktop:not(.no-icon) {
  &:after {
    @apply rounded-full;

    background: #ffffff33;
    background-image: url(./images/laptop.png);
    background-size: 65%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.learn-more {
  @apply text-gray text-center text-md hidden;
  @apply lg:block lg:absolute lg:mx-auto lg:mt-0 lg:-translate-x-1/2 lg:bottom-36 lg:left-1/2;

  &:after {
    @apply block absolute bg-cover;

    content: '';
    width: 30px;
    height: 52px;
    background-image: url(./images/arrow.svg);
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
  }
}

.teasers {
  @apply bg-no-repeat overflow-hidden;
  @apply md:px-6;
  @apply lg:px-6;

  background-image: url(./images/teasers_bg.svg);
  background-position: 50% -25vw;

  @media (min-width: 600px) {
    background-position: 50% -39vw;
  }

  @media (max-width: 833.99px) {
    background-size: 100%;
  }

  @media (min-width: 834px) {
    background-image: url(./images/teasers_bg--md.svg);
    background-position: 50% -13rem;
  }

  @media (min-width: 1024px) {
    background-image: url(./images/teasers_bg--md.svg);
    background-position: calc(50% - 10rem) -13rem;
  }

  .container {
    @apply w-full pt-20 pb-0;
    @apply md:pt-24 md:pb-16;
    @apply lg:pb-24;

    background-image: url(./images/teasers_ring.svg);
    background-size: 100%;
    background-position: 50% 95%;
    background-repeat: no-repeat;

    @media (min-width: 834px) {
      padding-top: 6rem;
      background-image: none;
    }

    @media (min-width: 1024px) {
      padding-top: 6.35rem;
    }
  }
}

.teasers-screen {
  @apply block relative;

  &--desktop {
    @apply mx-auto mt-5 mb-12;
    @apply md:mt-6 md:mb-30;
    @apply lg:mt-28;

    @media (min-width: 834px) {
      width: 700px;
      height: 438px;
    }

    @media (min-width: 1024px) {
      width: 700px;
      height: 470px;
      position: relative;
      left: 0.75rem;
    }
  }

  &--mobile {
    @apply absolute top-15;

    filter: drop-shadow(-10px 4px 20px rgba(0, 18, 27, 0.25));

    picture {
      z-index: 2;
      position: relative;
    }

    right: 2.5rem;
    width: 30%;

    @media (min-width: 834px) {
      width: 167px;
      height: 339px;
      top: 7.25rem;
      right: 5.5rem;
    }

    @media (min-width: 1024px) {
      width: 188px;
      height: 382px;
      right: 14.5rem;
    }
  }
}

.teasers-popup {
  @apply p-8 w-full my-3 z-10;
  @apply md:mt-0 md:mb-7;
  @apply lg:absolute lg:m-0 lg:pt-11;

  @media (min-width: 1024px) {
    width: 24.5rem;
  }

  background-color: rgba(37, 50, 69, 0.65);
  backdrop-filter: blur(10px);
  border-radius: 25px;

  background-image: url(images/teasers_stroke.svg);
  background-position: right bottom;
  background-repeat: no-repeat;

  h3 {
    @apply text-center text-light;
    @apply mb-2.5 md:mb-4 lg:mb-2.5;
    @apply lg:text-left;
  }

  p {
    @apply text-center lg:text-left;
  }
}

.all-devices {
  h3 {
    @apply text-center mt-20;

    @media (min-width: 834px) {
      margin-top: 6.5rem;
      margin-bottom: 5rem;
    }

    @apply lg:mt-72 lg:mb-24;
  }

  &__icons {
    @apply flex w-full flex-col mt-20;
    @apply md:flex-row md:justify-center;
  }

  &__icon {
    @apply block w-30 h-22 mx-auto bg-no-repeat mb-16 bg-center;
    @apply md:mb-0;

    @media (min-width: 834px) {
      margin-left: 4.5rem;
      margin-right: 4.5rem;
    }

    &:nth-child(1) {
      background-image: url(./images/laptop.svg);
    }

    &:nth-child(2) {
      background-image: url(./images/tablet.svg);
    }

    &:nth-child(3) {
      background-image: url(./images/phone.svg);

      @media (min-width: 834px) {
        background-position: 0 calc(100% - 2.5px);
      }
    }
  }
}

.disclaimer {
  @apply pb-16;
  @apply md:pb-22;

  h3 {
    margin-top: 1rem;
    margin-bottom: 1.8rem;

    @media (min-width: 834px) {
      margin-top: 6.5rem;
      margin-bottom: 2.5rem;
    }

    @apply lg:mt-0 lg:mb-12;
  }

  p:not(:last-child) {
    @apply mb-4;
  }
}

.footer {
  @apply bg-background-light bg-no-repeat bg-bottom text-center;

  background-image: url(./images/footer_bg.svg);
  background-size: 200%;

  @media (min-width: 1024px) {
    background-size: 100%;
  }

  .container {
    @apply w-full pt-7 pb-6;
    @apply md:pt-10 md:pb-4;
    @apply lg:pt-14 lg:pb-6;

    overflow: hidden;
  }

  &__menu {
    @apply text-center;
    @apply mt-8 md:mt-10 lg:mt-16 -mx-5 px-5 pt-4;
    @apply border-t border-t-light border-opacity-30;
    @apply flex justify-center items-center space-x-5 lg:space-x-10;

    a {
      @apply text-light text-opacity-70 font-normal text-xs md:text-md hover:text-white hover:text-opacity-100;
    }
  }
}
